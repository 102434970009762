/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const NotificationsIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21 17.8H3c-.3 0-.6-.2-.7-.5-.1-.3 0-.7.3-.8 0 0 2.7-1.9 2.7-8.4 0-3.7 3-6.8 6.8-6.8 3.7 0 6.8 3 6.8 6.8 0 6.4 2.6 8.4 2.7 8.4.3.2.4.5.3.8-.3.2-.6.5-.9.5zM4.7 16.2h14.6c-.9-1.4-2.1-3.9-2.1-8.2 0-2.9-2.4-5.2-5.2-5.2-2.9 0-5.2 2.3-5.2 5.2 0 4.3-1.2 6.9-2.1 8.2zM12 22.7c-.5 0-.9-.1-1.4-.4-.4-.2-.8-.6-1-1-.2-.4-.1-.8.3-1 .4-.2.8-.1 1 .3.1.2.3.3.5.5.6.3 1.4.1 1.7-.5.2-.4.7-.5 1-.3.4.2.5.7.3 1-.5 1-1.5 1.4-2.4 1.4z" />
	</svg>
))
NotificationsIconSvg.displayName = 'NotificationsIconSvg'

const NotificationsIcon = forwardRef((props, ref) => <Icon component={NotificationsIconSvg} ref={ref} {...props} />)
NotificationsIcon.displayName = 'NotificationsIcon'

NotificationsIcon.defaultProps = {
	...Icon.defaultProps,
}
NotificationsIcon.propTypes = {
	...Icon.propTypes,
}

export default NotificationsIcon
export { NotificationsIconSvg }
