/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const SearchIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M21.5 20.5l-3.8-3.8c1.3-1.5 2.1-3.5 2.1-5.6 0-4.8-3.9-8.8-8.8-8.8-4.8 0-8.8 3.9-8.8 8.8 0 4.8 3.9 8.8 8.8 8.8 2.1 0 4.1-.8 5.6-2.1l3.8 3.8c.1.1.3.2.5.2s.4-.1.5-.2c.4-.4.4-.8.1-1.1zM3.8 11C3.8 7 7 3.8 11 3.8s7.2 3.3 7.2 7.2c0 2-.8 3.8-2.1 5.1-1.3 1.3-3.1 2.1-5.1 2.1-4 0-7.2-3.2-7.2-7.2z" />
	</svg>
))
SearchIconSvg.displayName = 'SearchIconSvg'

const SearchIcon = forwardRef((props, ref) => <Icon component={SearchIconSvg} ref={ref} {...props} />)
SearchIcon.displayName = 'SearchIcon'

SearchIcon.defaultProps = {
	...Icon.defaultProps,
}
SearchIcon.propTypes = {
	...Icon.propTypes,
}

export default SearchIcon
export { SearchIconSvg }
