/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const XIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M13.1 12l5.5-5.5c.3-.3.3-.8 0-1.1s-.8-.3-1.1 0L12 10.9 6.5 5.5c-.3-.3-.8-.3-1.1 0s-.3.8 0 1.1l5.5 5.5-5.5 5.5c-.3.3-.3.8 0 1.1.1.1.3.2.5.2s.4-.1.5-.2l5.5-5.5 5.5 5.5c.1.1.3.2.5.2s.4-.1.5-.2c.3-.3.3-.8 0-1.1L13.1 12z" />
	</svg>
))
XIconSvg.displayName = 'XIconSvg'

const XIcon = forwardRef((props, ref) => <Icon component={XIconSvg} ref={ref} {...props} />)
XIcon.displayName = 'XIcon'

XIcon.defaultProps = {
	...Icon.defaultProps,
}
XIcon.propTypes = {
	...Icon.propTypes,
}

export default XIcon
export { XIconSvg }
